import React from 'react';
import Layout from './layout';

export const CompactLayout = ({ children, pageTitle }) => {
  return (
    <Layout pageTitle={pageTitle} isCompactLayout>
      <div className='mx-6 flex flex-col items-center'>
        <div className='w-full lg:w-5/6'>{children}</div>
      </div>
    </Layout>
  );
};

export default CompactLayout;
